/* You can add global styles to this file, and also import other style files */

/* SCSS */
@import "scss/style";
.required:after {
  content: " *";
  color: red;
}


@at-root{
  .formC{
      display: block;
      width: 100%;
      height: calc( 1.5em + 1.25rem + 2px );
      padding: 0.625rem 1.1875rem;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      position: relative;
      flex: 1 1 auto;
      width: 1%;
      min-width: 0;
      margin-bottom: 0;
      border: none;
      border-bottom: 2px solid #ced4da;
      background: no-repeat center bottom, center calc(100% - 1px);
      background-size: 0 100%, 100% 100%;
      transition:  border-bottom-color 0.3s ease-in-out;
      
      &:focus{
          border-bottom-color: #fc695f;
      }
  }
  .my-input::placeholder{
      color: #adb5bd;
      
  }
  
}
